import { CssThemeVariable } from '@activebrands/core-web/types/css-types';

interface GeneralCssVariables {
    [key: CssThemeVariable]: CssThemeVariable | string;
}

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const generalCssVariables: GeneralCssVariables = {
    // KARI TRAA NEW VARIABLES
    // Add new variables here and remove the old ones when all components are updated to use the new variables.

    // ----------------------------//
    // Gradient
    // ----------------------------//

    '--color-bg-gradient': 'var(--color-base-white-85)',

    // ----------------------------//
    // Global General Variables
    // ---------------------------//

    '--color-text': 'var(--color-base-brand-black)',
    '--color-bg': 'var(--color-base-white)',
    '--color-link': 'var(--color-base-brand-black)',
    '--color-icon-arrow-button': 'var(--color-base-brand-orange)',
    '--color-border': 'var(--color-base-brand-grey-1)',

    // ----------------------------//
    // Input
    // ---------------------------//

    '--color-text-input': 'var(--color-base-brand-black)',
    '--color-text-subtle-input': 'var(--color-base-brand-grey-3)',
    '--color-border-input': 'var(--color-base-brand-grey-2)',

    // Basket
    '--color-border-basket': 'var(--color-base-brand-grey-1)',

    // Select
    '--color-border-select': 'var(--color-base-brand-grey-2)',

    // ----------------------------//
    //Background
    // ----------------------------//

    '--color-bg-highlight': 'var(--color-base-white)',
    '--color-bg-filter': 'var(--color-base-white)',
    '--color-bg-secondary': 'var(--color-base-white)',
    '--color-bg-list-bullet': 'var(--color-base-brand-black)',

    // ----------------------------//
    // Text
    // ---------------------------//

    '--color-text-accent': 'var(--color-base-error)',
    '--color-text-disabled': 'var(--color-base-brand-grey-3)',
    '--color-text-error': 'var(--color-base-error)',
    '--color-text-inverted': 'var(--color-base-white)',
    '--color-text-subtle': 'var(--color-base-brand-grey-3)',
    '--color-text-pagination': 'var(--color-base-brand-black)',
    '--color-text-pagination-subtle': 'var(--color-base-brand-grey-4)',
    '--color-text-chosen-page': 'var(--color-base-brand-orange)',

    // ----------------------------//
    // Background borders
    // ----------------------------//

    '--color-border-pagination-arrow-icon': 'var(--color-base-brand-grey-2)',

    // Product card
    '--color-text-product-card': 'var(--color-base-brand-black)',
    '--color-bg-quickshop-overlay': 'var(--color-base-white)',
    '--color-text-quickshop-overlay': 'var(--color-base-brand-grey-dark)',
    '--color-border-buttons-quickshop': 'var(--color-base-brand-grey-1)',
    '--color-border-buttons-quickshop-overlay': 'var(--color-base-brand-black)',

    // Product card mini
    '--color-text-product-card-mini': 'var(--color-base-brand-black)',
    '--color-text-subtle-product-card-mini': 'var(--color-base-brand-grey-4)',
    '--color-text-remove-product-card-mini': 'var(--color-base-brand-orange)',

    // Feature Card
    '--color-border-feature-card': 'var(--color-base-brand-grey-1)',

    // ----------------------------//
    // Badges
    // ----------------------------//

    // Default
    '--color-bg-badge': 'var(--color-base-brand-grey-1)',
    '--color-text-badge': 'var(--color-base-brand-black)',

    // Brand primary
    '--color-bg-badge-brand-primary': 'var(--color-base-temp-blue-9)',
    '--color-text-badge-brand-primary': 'var(--color-base-temp-blue-1)',

    // Accent
    '--color-bg-badge-accent': '--color-base-temp-blue-1',
    '--color-text-badge-accent': 'var(--color-base-temp-blue-10)',

    // ----------------------------//
    // PLP
    // ----------------------------//

    '--color-text-plp': 'var(--color-base-brand-black)',
    '--color-text-subtle-plp': 'var(--color-base-brand-grey-1)',
    '--color-border-variant-image-active': 'var(--color-base-brand-black)',
    '--color-border-variant-image-hover': 'var(--color-base-brand-grey-2)',
    '--color-box-shadow-variant-image': 'var(--color-base-white)',

    // ----------------------------//
    // TeamStore PLP
    // ----------------------------//
    '--color-bg-infobox-teamstore': 'var(--color-base-brand-grey-4)',

    // ----------------------------//
    // Breadcrumbs
    // ----------------------------//

    '--color-slash-breadcrumbs': 'var(--color-base-brand-orange)',
    '--color-underline-breadcrumbs': 'var(--color-base-brand-grey-1)',

    // Article card
    '--color-text-article-tag': 'var(--color-base-brand-orange)',
    '--color-border-article-card': 'var(--color-base-brand-grey-1)',
    '--color-button-underline': 'var(--color-base-brand-orange)',
    '--color-border-featured-article-card': 'var(--color-base-brand-grey-1)',
    '--color-border-article-hero': 'var(--color-base-brand-grey-1)',

    // ----------------------------//
    // Navigation
    // ----------------------------//

    '--color-bg-navigation': 'var(--color-base-white)',
    '--color-text-navigation': 'var(--color-base-brand-black)',

    // Filter
    '--color-text-filter': 'var(--color-base-brand-black)',
    '--color-text-subtle-filter': 'var(--color-base-brand-grey-1)',

    // Sortbar
    '--color-bg-sortbar': 'var(--color-base-white)',
    '--color-text-sortbar': 'var(--color-base-brand-black)',
    '--color-text-subtle-sortbar': 'var(--color-base-brand-grey-1)',
    '--color-bg-filter-checked': 'var(--color-base-brand-orange)',

    // Sticky filter bar
    '--color-bg-sticky-filter-bar': 'var(--color-base-white-85)',
    '--color-border-sticky-filter-bar': 'var(--color-base-brand-grey-1)',
    '--color-text-sticky-filter-bar': 'var(--color-base-brand-black)',

    //Radio input
    '--color-border-radio': 'var(--color-base-brand-black)',
    '--color-bg-radio-checked': 'var(--color-base-brand-black)',
    '--color-border-radio-checked': 'var(--color-base-brand-black)',

    // Checkbox input
    '--color-bg-checkbox': 'var(--color-base-white)',
    '--color-bg-checkbox-checked': 'var(--color-base-brand-black)',
    '--color-border-checkbox-inverted': 'var(--color-base-brand-black)',
    '--color-border-checkbox-checked': 'var(--color-base-brand-black)',
    '--color-border-checkbox': 'var(--color-base-brand-black)',
    '--color-label-checkbox-loading': 'var(--color-base-brand-grey-3)',
    '--color-label-checkbox-disabled': 'var(--color-base-brand-grey-3)',

    // Account
    '--color-text-account': 'var(--color-base-brand-black)',
    '--color-text-subtle-account': 'var(--color-base-brand-grey-4)',
    '--color-border-account': 'var(--color-base-brand-grey-1)',
    '--color-bg-account': 'var(--color-base-white)',
    '--color-icon-vouchers': 'var(--color-base-brand-black)',
    '--color-bg-navigation-active': 'var(--color-base-black-06)',
    '--color-bg-navigation-mobile': 'var(--color-base-white-85)',
    '--color-border-navigation-menu': 'var(--color-base-brand-grey-2)',
    '--color-border-account-navigation': 'var(--color-base-brand-orange)',
    '--color-bg-active-menu': 'var(--color-base-brand-orange)',
    '--color-bg-navigation-menu': 'var(--color-base-white-85)',
    '--color-bg-navigation-overlay': 'var(--color-base-white)',
    '--color-bg-menu-button': 'var(--color-base-white)',

    // Auth
    '--color-text-auth': 'var(--color-base-brand-black)',
    '--color-underline-auth': 'var(--color-base-brand-orange)',

    // Act
    '--color-text-act': 'var(--color-base-white)',
    '--color-bg-act': 'var(--color-base-white)',

    // Change password overlay
    '--color-bg-password-overlay': 'var(--color-base-white)',

    // BrandBlock
    '--color-border-brand-block': 'var(--color-base-brand-grey-1)',
    '--color-text-subtle-brand-block': 'var(--color-base-brand-grey-4)',

    // State icon
    '--color-state-icon': 'var(--color-base-brand-orange)',

    // Orders
    '--color-bg-information-label': 'var(--color-base-brand-grey-1)',
    '--color-border-active-accordion': 'var(--color-base-brand-orange)',

    // ----------------------------//
    // Background
    // ----------------------------//

    '--color-bg-inverted': 'var(--color-base-brand-black)',

    // ----------------------------//
    // Checkout Response Page
    // ----------------------------//

    '--color-border-checkout-product-card-wrapper': 'var(--color-base-brand-grey-1)',

    // ----------------------------//
    // Added to basket
    // ---------------------------//

    '--color-border-added-to-basket': 'var(--color-base-brand-grey-1)',

    // ----------------------------//
    // Notification
    // ---------------------------//

    '--color-bg-notification-wrapper': 'var(--color-base-white)',
    '--color-border-notification-wrapper': 'var(--color-base-brand-grey-1)',

    // ----------------------------//
    // Beta Form
    // ---------------------------//

    '--color-text-beta-form': 'var(--color-base-white)',
    '--color-bg-beta-form': 'var(--color-base-brand-orange)',
    '--color-border-beta-form': 'var(--color-base-black-25)',

    // ----------------------------//
    // Size guide overlay
    // ---------------------------//

    '--color-border-size-guide': 'var(--color-base-brand-grey-1)',

    //OLD VARIABLES
    //TODO: Remove these when all components are updated to use the new variables

    // ----------------------------//
    // Navigation
    // ---------------------------//

    '--color-text-nav-heading': 'var(--color-base-temp-blue-6)',

    // ----------------------------//
    // Swatches
    // ----------------------------//

    '--color-swatches-border-color-highlight-inner': 'var(--color-base-white)',
    '--color-swatches-border-color-highlight': 'var(--color-base-brand-black)',
    '--color-swatches-border-color': 'var(--color-base-brand-neutral)',

    // ----------------------------//
    // Background
    // ----------------------------//

    '--color-bg-accent': 'var(--color-base-temp-blue-4)',
    '--color-bg-error': 'var(--color-base-temp-blue-6)',
    '--color-bg-contrast': 'var(--color-base-temp-blue-2)',

    '--color-bg-active-navigation': 'var(--color-base-temp-blue-9)',
    '--color-bg-pagination-arrow-icon': 'var(--color-base-temp-blue-2)',

    // ----------------------------//
    // Background borders
    // ----------------------------//

    '--color-border-subtle': 'var(--color-base-temp-blue-9)',
    '--color-border-accent': 'var(--color-base-temp-blue-10)',
    '--color-border-error': 'var(--color-base-temp-blue-10)',

    // ----------------------------//
    // Contrast
    // ----------------------------//

    '--color-contrast-lowest': 'var(--color-base-temp-blue-2)',
    '--color-contrast-lower': 'var(--color-base-temp-blue-9)',
    '--color-contrast-low': 'var(--color-base-temp-blue-9)',
    '--color-contrast-medium': 'var(--color-base-temp-blue-7)',
    '--color-contrast-high': 'var(--color-base-temp-blue-9)',
    '--color-contrast-higher': 'var(--color-base-temp-blue-9)',
    '--color-contrast-dark': 'var(--color-base-temp-blue-10)',

    // ----------------------------//
    // Shadow
    // ----------------------------//

    '--color-shadow': 'var(--color-base-temp-blue-7)',
    '--color-shadow-inverted': 'var(--color-base-temp-blue-1)',
    '--shadow-drop': 'var(--color-base-temp-blue-9)',

    // ACT colors
    '--color-text-voucher-subtle': 'var(--color-base-temp-blue-1)',

    // ----------------------------//
    // Header Overlays
    // ----------------------------//

    '--color-bg-header-overlay': 'var(--color-base-temp-blue-2)',

    // ----------------------------//
    // Tags
    // ----------------------------//

    '--color-bg-article-tag': 'var(--color-base-temp-blue-9)',
    '--color-bg-order-tag': 'var(--color-base-temp-blue-5)',

    // ----------------------------//
    // Preview
    // ----------------------------//

    '--color-bg-preview': 'var(--color-base-temp-blue-7)',
    '--color-bg-preview-button': 'var(--color-base-temp-blue-7)',

    // ----------------------------//
    // Images
    // ----------------------------//

    '--color-bg-image': 'var(--color-base-brand-grey-1)',

    // ----------------------------//
    // Icons
    // ----------------------------//

    // Info
    '--color-info-icon-border': 'var(--color-base-brand-orange)',

    // Category buttons
    '--color-text-category-button': 'var(--color-base-temp-blue-9)',
    '--color-bg-category-button': 'var(--color-base-temp-blue-9)',
    '--color-text-category-button-active': 'var(--color-base-temp-blue-1)',
    '--color-bg-category-button-active': 'var(--color-base-temp-blue-9)',
    '--color-text-category-button-hover': 'var(--color-base-temp-blue-1)',
    '--color-bg-category-button-hover': 'var(--color-base-temp-blue-9)',
    '--color-border-button': 'var(--color-base-temp-blue-1)',
};

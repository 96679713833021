import { ButtonCssVariables } from 'config/branding/interfaces/buttons';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const buttonsCssVariables: ButtonCssVariables = {
    // ----------------------------//
    // Default
    // ---------------------------//
    '--color-bg-button-default': 'var(--color-base-white)',
    '--color-text-button-default': 'var(--color-base-brand-black)',
    '--color-icon-button-default': 'var(--color-base-brand-orange)',
    '--transition-all-button-default': 'var(--transition-fast)',

    // Default (Hover)
    '--color-bg-button-default-hover': 'var(--color-base-brand-orange)',
    '--color-text-button-default-hover': 'var(--color-base-white)',
    '--color-icon-button-default-hover': 'var(--color-base-white)',

    // Default (Active)
    '--color-bg-button-default-active': 'var(--color-base-white)',
    '--color-text-button-default-active': 'var(--color-base-brand-black)',
    '--color-icon-button-default-active': 'var(--color-base-brand-orange)',
    '--color-border-button-default-active': 'var(--color-base-brand-orange)',

    // Default (Disabled)
    '--color-bg-button-default-disabled': 'var(--color-base-brand-grey-1)',
    '--color-text-button-default-disabled': 'var(--color-base-brand-grey-3)',
    '--color-icon-button-default-disabled': 'var(--color-base-brand-grey-3)',

    // ----------------------------//
    // Inverted
    // ---------------------------//
    '--color-bg-button-inverted': 'var(--color-base-brand-orange)',
    '--color-text-button-inverted': 'var(--color-base-white)',
    '--color-icon-button-inverted': 'var(--color-base-white)',
    '--transition-all-button-inverted': 'var(--transition-fast)',

    // Inverted (Hover)
    '--color-bg-button-inverted-hover': 'var(--color-base-white)',
    '--color-text-button-inverted-hover': 'var(--color-base-brand-black)',
    '--color-icon-button-inverted-hover': 'var(--color-base-brand-orange)',

    // Inverted (Active)
    '--color-bg-button-inverted-active': 'var(--color-base-white)',
    '--color-text-button-inverted-active': 'var(--color-base-brand-black)',
    '--color-border-button-inverted-active': 'var(--color-base-brand-orange)',
    '--color-icon-button-inverted-active': 'var(--color-base-brand-orange)',

    // Inverted (Disabled)
    '--color-bg-button-inverted-disabled': 'var(--color-base-brand-grey-1)',
    '--color-text-button-inverted-disabled': 'var(--color-base-brand-grey-3)',
    '--color-icon-button-inverted-disabled': 'var(--color-base-brand-grey-3)',

    // ----------------------------//
    // Outlined
    // ---------------------------//
    '--color-bg-button-outlined': 'var(--color-base-white)',
    '--color-text-button-outlined': 'var(--color-base-brand-black)',
    '--color-border-button-outlined': 'var(--color-base-brand-grey-2)',
    '--color-icon-button-outlined': 'var(--color-base-brand-orange)',
    '--transition-all-button-outlined': 'var(--transition-fast)',

    // outlined (Hover)
    '--color-bg-button-outlined-hover': 'var(--color-base-brand-orange)',
    '--color-text-button-outlined-hover': 'var(--color-base-white)',
    '--color-icon-button-outlined-hover': 'var(--color-base-white)',

    // outlined (Active)
    '--color-bg-button-outlined-active': 'var(--color-base-white)',
    '--color-text-button-outlined-active': 'var(--color-base-brand-black)',
    '--color-border-button-outlined-active': 'var(--color-base-brand-orange)',
    '--color-icon-button-outlined-active': 'var(--color-base-brand-orange)',

    // outlined (Disabled)
    '--color-bg-button-outlined-disabled': 'var(--color-base-brand-grey-1)',
    '--color-text-button-outlined-disabled': 'var(--color-base-brand-black)',
    '--color-icon-button-outlined-disabled': 'var(--color-base-brand-grey-3)',
    '--color-bg-button-outlined-disabled-hover': 'var(--color-base-brand-grey-2)',
    '--color-border-button-outlined-disabled': 'var(--color-base-brand-grey-2)',
    '--color-border-button-outlined-disabled-hover': 'var(--color-base-brand-black)',

    // ----------------------------//
    // Pagination
    // ---------------------------//
    '--color-bg-button-pagination': 'var(--color-base-white)',
    '--color-text-button-pagination': 'var(--color-base-brand-black)',
    '--color-border-button-pagination': 'var(--color-base-brand-grey-2)',
    '--color-icon-button-pagination': 'var(--color-base-brand-black)',
    '--transition-all-button-pagination': 'var(--transition-fast)',

    // pagination (Hover)
    '--color-bg-button-pagination-hover': 'var(--color-base-brand-orange)',
    '--color-text-button-pagination-hover': 'var(--color-base-white)',
    '--color-icon-button-pagination-hover': 'var(--color-base-white)',

    // pagination (Active)
    '--color-bg-button-pagination-active': 'var(--color-base-white)',
    '--color-text-button-pagination-active': 'var(--color-base-brand-black)',
    '--color-border-button-pagination-active': 'var(--color-base-brand-orange)',
    '--color-icon-button-pagination-active': 'var(--color-base-brand-orange)',

    // pagination (Disabled)
    '--color-bg-button-pagination-disabled': 'var(--color-base-brand-grey-1)',
    '--color-text-button-pagination-disabled': 'var(--color-base-brand-black)',
    '--color-icon-button-pagination-disabled': 'var(--color-base-brand-grey-3)',

    // ----------------------------//
    // Default - Secondary
    // ---------------------------//,
    '--color-text-button-defaultsecondary': 'var(--color-base-brand-black)',
    '--transition-all-button-defaultsecondary': 'var(--transition-fast)',
    '--color-icon-button-defaultsecondary': 'var(--color-base-brand-orange)',
    '--color-underline-button-defaultsecondary': 'var(--color-base-brand-orange)',
    // Default Secondary (Hover)
    '--color-text-button-defaultsecondary-hover': 'var(--color-base-brand-orange)',
    '--color-icon-button-defaultsecondary-hover': 'var(--color-base-brand-orange)',

    // Default Secondary (Active)
    '--color-text-button-defaultsecondary-active': 'var(--color-base-brand-orange)',
    '--color-icon-button-defaultsecondary-active': 'var(--color-base-brand-orange)',
    '--color-underline-button-defaultsecondary-active': 'var(--color-base-brand-orange)',
    // Default Secondary (Disabled)
    '--color-text-button-defaultsecondary-disabled': 'var(--color-base-brand-grey-3)',
    '--color-icon-button-defaultsecondary-disabled': 'var(--color-base-brand-grey-3)',
    '--color-bg-button-defaultsecondary-disabled': 'var(--color-base-brand-grey-1)',

    // ----------------------------//
    // Inverted - Secondary
    // ---------------------------//
    '--color-underline-button-invertedsecondary': 'var(--color-base-brand-orange)',
    '--color-text-button-invertedsecondary': 'var(--color-base-white)',
    '--transition-all-button-invertedsecondary': 'var(--transition-fast)',

    // Inverted - Secondary (Hover)
    '--color-icon-button-invertedsecondary-hover': 'var(--color-base-brand-orange)',
    '--color-text-button-invertedsecondary-hover': 'var(--color-base-brand-orange)',

    // Inverted - Secondary (Active)
    '--color-icon-button-invertedsecondary-active': 'var(--color-base-brand-orange)',
    '--color-text-button-invertedsecondary-active': 'var(--color-base-brand-orange)',

    // Inverted - Secondary (Disabled)
    '--color-text-button-invertedsecondary-disabled': 'var(--color-base-brand-grey-2)',
    '--color-icon-button-invertedsecondary-disabled': 'var(--color-base-brand-grey-2)',

    // ----------------------------//
    // Navigation
    // ---------------------------//
    '--color-bg-button-navigation': 'var(--color-base-white)',
    '--color-text-button-navigation': 'var(--color-base-brand-black)',
    '--transition-all-button-navigation': 'var(--transition-fast)',
    '--color-icon-button-navigation': 'var(--color-base-brand-black)',

    // navigation (Hover)
    '--color-bg-button-navigation-hover': 'var(--color-base-black-06)',
    '--color-text-button-navigation-hover': 'var(--color-base-brand-black)',
    '--color-icon-button-navigation-hover': 'var(--color-base-brand-black)',

    // navigation (Active)
    '--color-bg-button-navigation-active': 'var(--color-base-black-06)',
    '--color-text-button-navigation-active': 'var(--color-base-brand-black)',
    '--color-icon-button-navigation-active': 'var(--color-base-brand-orange)',

    /** Link */
    '--color-text-button-link': 'var(--color-base-brand-black)',
    '--color-underline-link': 'var(--color-base-brand-orange)',

    // Link (Hover)
    '--color-bg-button-link-hover': 'var(--color-base-brand-orange)',
    '--color-text-button-link-hover': 'var(--color-base-brand-orange)',
    // Link (Active)
    '--color-text-button-link-active': 'var(--color-base-brand-orange)',
    '--color-border-button-link-active': 'var(--color-base-brand-orange)',

    /** Variation */
    '--color-bg-button-variation': 'var(--color-base-white)',
    '--color-text-button-variation': 'var(--color-base-brand-black)',
    // '--transition-all-button-variation': 'var(--transition-fast)',

    // Variation (Hover)
    '--color-bg-button-variation-hover': 'var(--color-base-brand-orange)',
    '--color-text-button-variation-hover': 'var(--color-base-white)',
    '--color-icon-button-variation-hover': 'var(--color-base-white)',

    // Variation (Active)
    '--color-text-button-variation-active': 'var(--color-base-white)',
    '--color-bg-button-variation-active': 'var(--color-base-brand-orange)',

    // Variation (Disabled)
    '--color-text-button-variation-disabled': 'var(--color-base-brand-grey-1)',
    '--color-bg-button-variation-disabled': 'var(--color-base-brand-grey-4)',
};

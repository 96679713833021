import { HeaderCssVariables } from 'config/branding/interfaces/header';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const headerCssVariables: HeaderCssVariables = {
    '--color-bg-header': 'var(--color-base-white)',
    '--color-bg-navigation-labels': 'var(--color-base-brand-orange)',
    '--color-bg-search-button': 'var(--color-base-brand-grey-4)',
    '--color-bg-search-header': 'var(--color-base-black-06)',
    '--color-border-header': 'var(--color-base-brand-grey-1)',
    '--color-border-navigation': 'var(--color-base-brand-grey-1)',
    '--color-border-search-header-active': 'var(--color-base-brand-orange)',
    '--color-border-search-header': 'var(--color-base-brand-black)',
    '--color-border-search-input': 'var(--color-base-brand-grey-1)',
    '--color-border-search-overlay': 'var(--color-base-brand-grey-1)',
    '--color-logo-header': 'var(--color-base-temp-blue-10)',
    '--color-text-header': 'var(--color-base-brand-black)',
    '--color-text-navigation-labels': 'var(--color-base-white)',
    '--color-text-search-header': 'var(--color-base-brand-black)',
    '--color-text-search-input-placeholder': 'var(--color-base-brand-grey-4)',
    '--color-top-logo-mobile': 'var(--color-base-temp-blue-5)',
    '--color-logo-header': 'var(--color-base-brand-black)',
    '--color-top-logo-site-selector': 'var(--color-base-white)',
};

import ColorsCssVariables from '@activebrands/core-web/interfaces/colors';
import { CssVariables, HEX, RGB, RGBA } from '@activebrands/core-web/types/css-types';

export interface ColorCssVariables extends CssVariables<RGBA | RGB | HEX> {
    '--color-base-black-06': RGBA | RGB | HEX;
    '--color-base-black-20': RGBA | RGB | HEX;
    '--color-base-black': RGBA | RGB | HEX;
    '--color-base-brand-black': RGBA | RGB | HEX;
    '--color-base-brand-grey-1': RGBA | RGB | HEX;
    '--color-base-brand-grey-2': RGBA | RGB | HEX;
    '--color-base-brand-grey-3': RGBA | RGB | HEX;
    '--color-base-brand-grey-4': RGBA | RGB | HEX;
    '--color-base-brand-neutral': RGBA | RGB | HEX;
    '--color-base-brand-orange': RGBA | RGB | HEX;
    '--color-base-brand-white': RGBA | RGB | HEX;
    '--color-base-error': RGBA | RGB | HEX;
    '--color-base-success': RGBA | RGB | HEX;
    '--color-base-white-85': RGBA | RGB | HEX;
    '--color-base-white': RGBA | RGB | HEX;
}

export const colorsCssVariables: ColorsCssVariables = {
    // Default colors
    '--color-base-white': '#FFFFFF',
    '--color-base-black': '#000000',

    // Brand colors
    '--color-base-brand-black': '#151515',
    '--color-base-brand-grey-1': '#E3E3E3',
    '--color-base-brand-grey-2': '#C6C6C6',
    '--color-base-brand-grey-3': '#595959',
    '--color-base-brand-grey-4': '#333333',
    '--color-base-brand-neutral': '#EEEAE5',
    '--color-base-brand-orange': '#ff7418',
    '--color-base-brand-red': '#FA2A2A',
    '--color-base-brand-white': '#FFFFFF',

    //Transparency colors
    '--color-base-black-20': 'rgba(0, 0, 0, 0.20)',
    '--color-base-black-06': 'rgba(0, 0, 0, 0.06)',

    '--color-base-white-85': 'rgba(255, 255, 255, 0.85)',

    // Informational
    '--color-base-error': '#CC0000',
    '--color-base-success': '#2CBF4C',
};

import { ModulesCssVariables } from 'config/branding/interfaces/modules';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const modulesCssVariables: ModulesCssVariables = {
    // DAHLIE MODULE VARIABLES

    // ----------------------------//
    // Discover
    // ----------------------------//

    '--color-bg-discover-module': 'var(--color-base-white)',
    '--color-border-discover-module': 'var(--color-base-brand-grey-1)',

    // ----------------------------//
    // Flexible
    // ----------------------------//
    '--color-bg-flexible-tag': 'var(--color-base-white)',
    '--color-text-tag': 'var(--color-base-brand-black)',

    // ----------------------------//
    // Shop the look
    // ----------------------------//
    '--color-bg-shop-the-look-tag': 'var(--color-base-white)',
    '--color-text-shop-the-look-tag': 'var(--color-base-brand-black)',
    '--color-text-shop-the-look-default': 'var(--color-base-brand-black)',
    '--color-text-shop-the-look-inverted': 'var(--color-base-white)',

    // ----------------------------//
    // Newsletter
    // ----------------------------//
    '--color-bg-newsletter': 'var(--color-base-white)',
    '--color-text-newsletter': 'var(--color-base-brand-black)',
    '--color-wrapper-border-newsletter': 'var(--color-base-brand-orange)',
    '--color-border-newsletter-input': 'var(--color-base-brand-black)',
    '--color-text-newsletter-input-placeholder': 'var(--color-base-brand-grey-3)',

    // ----------------------------//
    // Category
    // ----------------------------//
    '--color-text-category-module': 'var(--color-base-white)',
    '--color-bg-tag-category-module': 'var(--color-base-white)',
};

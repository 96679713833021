import { FooterCssVariables } from 'config/branding/interfaces/footer';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const footerCssVariables: FooterCssVariables = {
    '--color-bg-footer-primary': 'var(--color-base-white)',
    '--color-text-footer-primary': 'var(--color-base-brand-black)',
    '--color-border-footer-primary': 'var(--color-base-brand-black)',
    '--color-border-footer-secondary': 'var(--color-base-brand-grey-2)',
    '--color-border-footer-inverted': 'var(--color-base-brand-orange)',
    '--fill-logo-footer': 'var(--color-base-brand-black)',
};

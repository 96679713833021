import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import ClearSelectedSiteHandler from '@activebrands/core-web/components/ClearSelectedSiteHandler';
import WrongSiteHandler from '@activebrands/core-web/components/WrongSiteHandler';
import AnimatedOverlay from '@activebrands/core-web/components/overlays/AnimatedOverlay';
import BackdropOverlay from '@activebrands/core-web/components/overlays/BackdropOverlay';
import PageContainer from '@activebrands/core-web/containers/PageContainer';
import { GridCheck } from '@activebrands/core-web/libs/GridCheck';
import overlay, { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { resolveUser } from '@activebrands/core-web/state/user/actions';
import { setPage } from '@activebrands/core-web/state/page/actions';
import { setSiteSelectorCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import loadable from '@loadable/component';
import PageLayout from 'components/Layout/PageLayout';
import NotificationsOverlay from 'components/overlays/NotificationsOverlay';

const isGridOverlayEnabled = process.env.REACT_APP_OVERLAY_GRID_ENABLED === 'true';

const ErrorOverlay = loadable(() => import('components/overlays/ErrorOverlay'));
const NavigationOverlay = loadable(() => import('templates/account/NavigationOverlay'));
const NotifyMeOverlay = loadable(() => import('templates/ProductPage/NotifyMeOverlay'));
const NotWithinTeamOrderingPeriodOverlay = loadable(() =>
    import('components/overlays/NotWithinTeamOrderingPeriodOverlay')
);
const QuickshopOverlay = loadable(() => import('components/product/Quickshop/QuickshopOverlay'));
const RemovedTeamProductsFromBasketOverlay = loadable(() =>
    import('components/overlays/RemovedTeamProductsFromBasketOverlay')
);
const ShopTheLookOverlay = loadable(() => import('components/content-components/ShopTheLook/ShopTheLookOverlay'));
const SizeGuideOverlay = loadable(() => import('templates/ProductPage/SizeGuideOverlay'));
const UnauthenticatedOverlay = loadable(() => import('components/auth/UnauthenticatedOverlay'));
const ValidateTeamAccountOverlay = loadable(() => import('components/overlays/ValidateTeamAccountOverlay'));
const ValidateTeamAccountSuccessOverlay = loadable(() =>
    import('components/overlays/ValidateTeamAccountSuccessOverlay')
);
const WrongSiteOverlay = loadable(() => import('components/overlays/WrongSiteOverlay'));
const ZoomGalleryOverlay = loadable(() => import('components/overlays/ZoomGalleryOverlay'));

const HookWrapper = ({ page }) => {
    const dispatch = useTypedDispatch();
    const wrongSitePopupIsOpen = useOverlaySubscriber('wrong-site-overlay');
    const { siteSelectorLinks } = useSiteSelectorQuery();
    const quickshopIsOpen = useOverlaySubscriber('quickshop');
    const currentSiteId = useSelector(state => state.application.aliasId);
    const currentSite = siteSelectorLinks.find(site => site.id == currentSiteId);

    // This makes sure the quickshop overlays backdrop is on top of all other overlays
    const backdropStyles = quickshopIsOpen ? { zIndex: 'var(--zindex-overlay)' } : {};

    const handleWrongSitePopupBackdropOnClick = () => {
        overlay.close('wrong-site-overlay');
        setSiteSelectorCookie(currentSite.path);
        dispatch(resolveUser());
    };

    dispatch(setPage(page));

    return (
        <>
            <BackdropOverlay
                $style={backdropStyles}
                handleOnClick={wrongSitePopupIsOpen ? handleWrongSitePopupBackdropOnClick : undefined}
                isVisible={wrongSitePopupIsOpen}
            />
            {page.type !== 'siteSelectorPage' && (
                <WrongSiteHandler alternateHrefs={page.data?.hrefs ? objectKeysToCamelCase(page.data.hrefs) : []} />
            )}
        </>
    );
};

HookWrapper.propTypes = {
    page: PropTypes.object.isRequired,
};

const Layout = ({ element, props: pageProps }) => {
    const page = pageProps.pageContext?.page || {};

    return (
        <>
            <PageContainer page={page}>
                <PageLayout {...pageProps} element={element}>
                    {element}
                </PageLayout>
            </PageContainer>
            {isGridOverlayEnabled && <GridCheck />}
            <NotificationsOverlay />
            <AnimatedOverlay lockScroll component={NotifyMeOverlay} id="notify-me" />
            <AnimatedOverlay lockScroll component={SizeGuideOverlay} id="sizeguide" />
            <AnimatedOverlay
                lockScroll
                $style={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: ['flex-end', null, null, null, null, 'flex-start'],
                }}
                component={UnauthenticatedOverlay}
                id="unauthenticated"
            />
            <AnimatedOverlay
                lockScroll
                $style={{ zIndex: 'var(--zindex-pdp-gallery)', top: '0', height: '100vh' }}
                component={ZoomGalleryOverlay}
                id="zoom-gallery"
            />
            <AnimatedOverlay lockScroll animation="slideLTR" component={NavigationOverlay} id="account-navigation" />
            <AnimatedOverlay
                lockScroll
                $style={{ height: 'auto', top: '50%', zIndex: 'var(--zindex-wrong-site-popup)' }}
                animation="slideBTM"
                animationDuration="none"
                component={WrongSiteOverlay}
                id="wrong-site-overlay"
            />
            <AnimatedOverlay lockScroll component={ShopTheLookOverlay} id="shop-the-look" />
            <AnimatedOverlay
                lockScroll
                $style={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: ['flex-end', null, null, null, null, 'flex-start'],
                }}
                component={ValidateTeamAccountOverlay}
                id="validate-team-account"
            />
            <AnimatedOverlay
                lockScroll
                $style={{ height: 'auto', top: '50%' }}
                animation="slideBTM"
                animationDuration="none"
                component={ValidateTeamAccountSuccessOverlay}
                id="validate-team-account-success"
            />
            <AnimatedOverlay
                lockScroll
                $style={{ height: 'auto', top: '50%' }}
                animation="slideBTM"
                animationDuration="none"
                component={NotWithinTeamOrderingPeriodOverlay}
                id="not-within-team-ordering-period"
            />
            <AnimatedOverlay
                lockScroll
                $style={{ height: 'auto', top: '50%' }}
                animation="slideBTM"
                animationDuration="none"
                component={RemovedTeamProductsFromBasketOverlay}
                id="removed-team-products-from-basket"
            />
            <AnimatedOverlay
                lockScroll
                $style={{
                    display: ['initial', null, null, 'none'],
                    bottom: 'var(--height-header)',
                    zIndex: 'calc(var(--zindex-overlay) + 1)',
                    width: '100%',
                }}
                animation="slideBTT"
                component={QuickshopOverlay}
                id="quickshop"
            />
            <AnimatedOverlay
                lockScroll
                $style={{ height: 'auto', top: '50%', zIndex: 'calc(var(--zindex-overlay) + 1)' }}
                animation="slideBTM"
                animationDuration="none"
                component={ErrorOverlay}
                id="error-overlay"
            />
            <HookWrapper page={page} />
            <ClearSelectedSiteHandler />
        </>
    );
};

Layout.propTypes = {
    element: PropTypes.node.isRequired,
    props: PropTypes.object.isRequired,
};

export default Layout;

import { CssResponsiveVariables } from '@activebrands/core-web/types/css-types';

type Ratio = number;
export interface RatiosCssResponsiveVariables {
    '--ratio-horizontal-primary'?: Ratio;
    '--ratio-square'?: Ratio;
    '--ratio-portrait'?: Ratio;
    '--ratio-product-image'?: Ratio;
}

export const ratiosCssResponsiveVariables: CssResponsiveVariables<RatiosCssResponsiveVariables> = {
    'mobile.sm': {
        '--ratio-horizontal-primary': 16 / 9,
        '--ratio-horizontal-secondary': 5 / 4,
        '--ratio-horizontal': 3 / 2,
        '--ratio-module-background-media': 4 / 5,
        '--ratio-module-call-to-action': 2 / 3,
        '--ratio-module-flexible': 2 / 3,
        '--ratio-module-shop-the-look': 2 / 3,
        '--ratio-module-three-columns': 2 / 3,
        '--ratio-module-two-columns': 2 / 3,
        '--ratio-module-video-player': 2 / 3,
        '--ratio-square': 1,
        '--ratio-vertical-primary': 4 / 5,
        '--ratio-vertical-secondary': 3 / 4,
    },
    'mobile.md': {},
    'mobile.lg': {},
    'tablet.sm': {
        '--ratio-module-background-media': 1,
        '--ratio-module-call-to-action': 1,
        '--ratio-module-flexible': 1,
        '--ratio-module-shop-the-look': 1,
        '--ratio-module-video-player': 1,
    },
    'tablet.lg': {},
    'desktop.sm': {
        '--ratio-module-background-media': 16 / 9,
        '--ratio-module-call-to-action': 16 / 9,
        '--ratio-module-shop-the-look': 19 / 10,
        '--ratio-module-two-columns': 1,
        '--ratio-module-video-player': 16 / 9,
    },
    'desktop.md': {},
    'desktop.lg': {},
};
